<template>
  <section>
    <div class="content-header">
      <h2>Navigating career turbulence </h2>
    </div>
    <div class="content-wrapper">
      <p>How can you prepare students for the instability that may lie ahead in their chosen careers? This is particularly relevant as we navigate the post-COVID world of work.</p>
      <p>The <a href="https://podcasts.apple.com/us/podcast/navigating-career-turbulence/id1346314086?i=1000515923769" target="_blank">“Navigating Career Turbulence”</a> episode of Adam Grant’s WorkLife podcast features “people who have taken unusual steps to battle uncertainty, rethought their approach to finding and landing a job, and reached out for help in unexpected places—as well as an expert on recessions who forecasts the future by looking to the past.”</p>
      <p>Share this episode with students to help them chart a new course when turbulence inevitably hits.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '06',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
